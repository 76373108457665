<template>
  <a
    :href="link || zRoute('dashboard')"
    class="shrink-0 block"
    style="max-width: 208px; max-height: 100px"
  >
    <img v-if="themeEnabled" class="w-full" :src="themeLogo" alt="Logo" />
    <LogoKDNSelect v-else class="w-full" />
  </a>
</template>

<script setup>
import { usePageProps } from "@/composables/pageProps"
import { zRoute } from "@/helpers/route-helpers"
import LogoKDNSelect from "./LogoKDNSelect.vue"

defineProps({
  link: String,
})

const { themeLogo, themeEnabled } = usePageProps()
</script>
